<template>
  <div class="sub-forum center">
    <br>
    <ATitle text="第二届长三角综合交通发展大会"></ATitle>
    <br>
    <div class="forum-content rich-text" v-html="info.content"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.$http.get('/meeting/forum/2',{
    }).then(res => {
      if (res.code === 0) {
        this.info = res.data
      } else {
        this.$Message.warning(res.msg)
      }
    }).catch(err => {
      this.$Message.error(err)
    })
  }
}
</script>

<style lang="less" scoped>
.forum-content {
  padding: 30px;
  min-height: calc(100vh - 600px);
  img {
    max-width: 100%;
  }
}
</style>
